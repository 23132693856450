import Spinner from "../components/fixedcomponents/Spinner.vue";
import { mapGetters } from "vuex";
import {
  getCommunicationTestCompletion,
  getInternetAndSystemCheck,
  tokenForUserMobileLogin,
} from "../apiFunction.js";
import jwt_decode from "jwt-decode";

const testDetails = {
  name: "TestDetails",
  components: {
    Spinner,
  },
  data: () => ({
    userToken: "",
    Loaderstatus: false,
    location: null,
    errorStr: null,
    locationpermission: false,
  }),
  computed: {
    ...mapGetters("sectionVUEX", {
      isLoadingStatus: "getIsLoadingStatus",
      testDetails: "getTestDetails",
      userDetials: "getUserDetails",
      uuid: "getUUID",
    }),
  },
  beforeCreate() {},
  created() {
    console.log("test details", this.testDetails);
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    this.askCameraPermission();
    this.askLocationPermission();
    this.userToken = jwt_decode(localStorage.getItem("vue_token"));
    this.$store.commit("sectionVUEX/saveUserID", this.userToken.sub);

    this.$store.commit("sectionVUEX/isLoadingStatus", true);
    this.getCmTestDeatils();
  },

  methods: {
    askLocationPermission() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.locationpermission = true;
          this.location = pos;
        },
        (err) => {
          console.log(err);
          this.locationpermission = false;
        },
        { enableHighAccuracy: true }
      );
    },
    async getCmTestDeatils() {
      this.isLoading = true;
      const res = await getCommunicationTestCompletion(
        this.testDetails.topicID,
        this.testDetails.testID,
        this.testDetails.attemptNo,
        this.testDetails.groupID
      );
      if (res.status == 200) {
        this.$store.commit(
          "sectionVUEX/saveIsCommunicationTestStatus",
          res.data.data.status
        );
      }
    },
    convertToIST(utcTimestamp) {
      const date = new Date(utcTimestamp);
      date.setHours(date.getHours());
      date.setMinutes(date.getMinutes());
      // Formatting the date
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Asia/Kolkata", // IST timezone--optional
      };
      return date.toLocaleString("en-US", options);
    },
    formatDateMob() {
      const options = {
        month: "short", // 'Aug'
        day: "2-digit", // '28'
        year: "numeric", // '2024'
        hour: "2-digit", // '06'
        minute: "2-digit", // '32'
        hour12: true, // 'AM/PM'
      };
      const dateStr = "2024-08-28T18:32:00.000Z";
      const date = new Date(dateStr);
      const formattedDate = date
        .toLocaleString("en-US", options)
        .replace(",", "");
      return formattedDate;
    },
    formatDate(inputDate, format) {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      format = format.replace("MM", month.toString().padStart(2, "0"));
      if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
      } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substring(2, 2));
      }
      format = format.replace("dd", day.toString().padStart(2, "0"));
      return format;
    },
    async next() {
      let token;
      if (localStorage.getItem("vue_token")) {
        token = localStorage.getItem("vue_token");
      } else {
        const res1 = await tokenForUserMobileLogin();
        token = res1.data.token;
      }
      const res = await getInternetAndSystemCheck(this.uuid, token);
      if (res.data.data.systemCheck) {
        const isCameraGranted = await this.checkPermissionGranted("camera");
        const isMicroGranted = await this.checkPermissionGranted("microphone");
        const isLocation = await this.checkPermissionGranted("geolocation");

        if (!isCameraGranted || !isMicroGranted || !isLocation) {
          this.$bvModal.show("systemcheckmodal");
        } else {
          this.gotoNext();
        }
      } else {
        const isLocation = await this.checkPermissionGranted("geolocation");
        if (!isLocation) {
          this.$bvModal.show("systemcheckmodal");
        } else {
          this.gotoNext();
        }
      }
    },
    gotoNext() {
      if (this.testDetails.config.allowExtension) {
        this.goToExtension();
      } else if (this.testDetails.config.allowAgent) {
        this.goToAgent();
      } else if (this.testDetails.config.allowImageAndIdCapture) {
        this.goToSelfieScreen();
      } else if (this.testDetails.config.typingTest) {
        this.goToTypingTest();
      } else if (this.testDetails.config.communicationTest) {
        this.goTocommunicationTest();
      } else {
        this.goToDosAndDonts();
      }
    },
    askCameraPermission() {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    },
    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
    procText() {
      let agent = this.testDetails.config.allowAgent;
      let ext = this.testDetails.config.allowExtension;
      let video = this.testDetails.config.enableProctoring;
      let text;
      if (agent && ext && video) {
        text = "Video proctoring, agent, and extension are enabled";
      } else if (agent && ext) {
        text = "Agent and extension are enabled";
      } else if (ext && video) {
        text = "Video proctoring and extension are enabled";
      } else if (video && agent) {
        text = "Video proctoring and agent are enabled";
      } else if (agent) {
        text = "Agent is enabled";
      } else if (ext) {
        text = "Extension is enabled";
      } else if (video) {
        text = "Video proctoring is enabled";
      }
      return text;
    },
    procImage() {
      let agent = this.testDetails.config.allowAgent;
      let ext = this.testDetails.config.allowExtension;
      let video = this.testDetails.config.enableProctoring;
      let icon;
      if (agent && ext && video) {
        icon = require(`../assets/newimgs/testscreen/proctoring.svg`);
      } else if (agent && ext) {
        icon = require(`../assets/newimgs/testscreen/extensionagentproc.svg`);
      } else if (ext && video) {
        icon = require(`../assets/newimgs/testscreen/videoextensionproc.svg`);
      } else if (video && agent) {
        icon = require(`../assets/newimgs/testscreen/videoagentproc.svg`);
      } else if (agent) {
        icon = require(`../assets/newimgs/testscreen/agentproc.svg`);
      } else if (ext) {
        icon = require(`../assets/newimgs/testscreen/extensionproc.svg`);
      } else if (video) {
        icon = require(`../assets/newimgs/testscreen/videoproc.svg`);
      }
      return icon;
    },
    leave() {
      if (localStorage.getItem("fromCandidate")) {
        window.close("", "_parent", "");
      }
      localStorage.clear();

      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },

    goToAgentAndExtension() {
      this.$router.push({
        path: "/AgentandExtension",
      });
    },
    goToAgent() {
      this.$router.push({
        path: "/agent",
      });
    },
    goToExtension() {
      this.$router.push({
        path: "/extension",
      });
    },
    goToTypingTest() {
      this.$router.push({
        path: "/typingtest",
      });
    },
    goTocommunicationTest() {
      this.$router.push({
        path: "/communicationtest",
      });
    },

    goToDosAndDonts() {
      this.navigate("DoAndDont");
    },
    goToSelfieScreen() {
      this.navigate("snapshots");
    },
    navigate(pageName) {
      this.$router.push({
        name: pageName,
      });
    },
    goToPayment() {
      this.$router.push({
        path: "/payment",
      });
    },
  },
};
export default testDetails;
